import { Config } from '../../modules/Config';
import { SigningType } from './common';

type CreateSigningPageUrlParams = {
  config: Config;
  signingType: SigningType;
  isMobile?: boolean;
};

export const createSigningPageUrl = ({ config, signingType }: CreateSigningPageUrlParams) => {
  const language = config.getLanguage();
  const postLoginUrl =
    language === 'en' ? 'http://manage.wix.com/logo/maker/esh' : `http://manage.wix.com/logo/maker/esh/welcome`;

  const searchParamsInstance = new URLSearchParams({
    color: 'LOGO',
    sendEmail: 'false',
    referralInfo: 'HEADER',
    redirectTo: postLoginUrl,
    postSignUp: postLoginUrl,
    originUrl: location.href,
    customized: 'logo_maker',
    forceRender: 'true',
  });

  if (language && language !== 'en') {
    searchParamsInstance.set('overrideLocale', language);
  }

  if (signingType === SigningType.SignUp) {
    searchParamsInstance.set('loginDialogContext', 'signin');
  } else if (signingType === SigningType.LogIn) {
    searchParamsInstance.set('loginDialogContext', 'login');
  }

  const urlInstance = new URL('https://users.wix.com/logo/signin');
  urlInstance.search = searchParamsInstance.toString();

  return urlInstance.href;
};
