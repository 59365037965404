import { BaseElementController } from '../../modules/BaseElementController';
import { MainMenuController } from '../MainMenu/MainMenuController';
import { LanguageController } from '../LanguageButton/LanguageController';
import { Header } from './Header';
import { HeaderStructure } from '../../data/types';
import { Config } from '../../modules/Config';
import { Translator } from '../../modules/translate';
import { DataFetcher, UserBrandMakerData, UserProfileData } from '../../modules/DataFetcher';
import { UserMenuController } from '../UserMenu/UserMenuController';
import { OverlayController } from '../Overlay/OverlayController';
import { MenuToggleController } from '../MenuToggle/MenuToggleController';
import { BiLogger } from '../../modules/BiLogger';
import s from './Header.scss';
import { Experiments } from '../../experiments';
import { SkipButtonController } from '../SkipButton/SkipButtonController';
import { DataHook } from '../../modules/dataHooks';
import { SigningSectionController } from '../SigningSection/SigningSectionController';
import { goToLogoMaker } from '../../modules/routingServices';
import { SigningButtonController } from '../SigningSection/SigningButtonController';
import { SigningSectionType, SigningType } from '../SigningSection/common';
import { renderGoogleOT } from '../SigningSection/GoogleOT';
import { goToBrandStudioDesktopAccountMenuItem, goToMyBrandsDesktopAccountMenuItem } from '../../data/headerStructure';

type Elements = {
  header: HTMLHeadingElement;
  container: HTMLDivElement;
  skipButton: HTMLButtonElement;
  signUpButtonContainer: HTMLDivElement;
};
interface HeaderControllerParams {
  headerStructure: HeaderStructure;
  config: Config;
  t: Translator;
  dataFetcher: DataFetcher;
  biLogger: BiLogger;
  experiments: Experiments;
}

type InitParams = {
  userProfileData: UserProfileData | null;
  userHaveBrandsOrLogos?: boolean;
};

export class HeaderController extends BaseElementController {
  private readonly headerStructure: HeaderStructure;
  private readonly config: Config;
  private readonly t: Translator;
  private readonly mainMenuController: MainMenuController;
  private readonly languageController: LanguageController;
  private readonly signingButtonController: SigningButtonController;
  private readonly signingSectionController: SigningSectionController;
  private userMenuController?: UserMenuController;
  private readonly overlayController: OverlayController;
  private readonly menuToggleController: MenuToggleController;
  private readonly skipButtonController: SkipButtonController;
  private readonly dataFetcher: DataFetcher;
  private readonly biLogger: BiLogger;
  private readonly experiments: Experiments;
  private readonly elements: Elements;
  private mobileSigningSectionType: SigningSectionType = SigningSectionType.ONE_BUTTON;

  private static userProfileData: UserProfileData | null = null;
  private static userProfileDataFetched = false;

  private static userBrandMakerData: UserBrandMakerData | null = null;
  private static userBrandMakerFetched = false;

  constructor({ headerStructure, config, t, dataFetcher, biLogger, experiments }: HeaderControllerParams) {
    super();

    const newHeaderElement = this.createElementFromHTML<HTMLHeadingElement>(
      Header({ t, structure: headerStructure, config }),
    );

    const currentHeaderElement = this.$<HTMLHeadingElement>('[data-wix-header=hf2021]', document);
    const headerWrapper = config.getHeaderWrapper();
    const headerPlaceholderElement = currentHeaderElement ?? headerWrapper;

    if (headerPlaceholderElement) {
      headerPlaceholderElement.replaceWith(newHeaderElement);
    } else {
      document.body.insertBefore(newHeaderElement, document.body.firstChild);
    }

    this.elements = {
      header: newHeaderElement,
      container: this.$<HTMLDivElement>(`.${s.header}`, newHeaderElement)!,
      skipButton: this.$<HTMLButtonElement>(this.getSelectorByDataHook(DataHook.SkipButton))!,
      signUpButtonContainer: this.$<HTMLDivElement>(`.${s.signUpButtonContainer}`)!,
    };

    this.headerStructure = headerStructure;
    this.config = config;
    this.t = t;
    this.biLogger = biLogger;
    this.experiments = experiments;

    this.mainMenuController = new MainMenuController({ biLogger, config, experiments, t });
    this.languageController = new LanguageController({
      languageList: this.headerStructure.languagePickerMenu,
      config,
      t,
      biLogger,
      headerElement: newHeaderElement,
    });
    this.signingButtonController = new SigningButtonController({ biLogger, config, t });
    this.signingSectionController = new SigningSectionController({ biLogger, config, t });
    this.overlayController = new OverlayController();
    this.menuToggleController = new MenuToggleController();
    this.skipButtonController = new SkipButtonController({
      button: this.elements.skipButton,
      mainContentSelector: config.getMainContentSelector(),
      isWixSite: this.config.isWixSite(),
    });
    this.dataFetcher = dataFetcher;
  }

  async init(initParams: InitParams) {
    this.mainMenuController.init({
      onClickMenuItem: () => {
        if (this.userMenuController?.isExpanded()) {
          this.userMenuController?.collapseMenu();
        }
        if (!this.isDesktop() && this.languageController.isOpenedLanguageMobileMenu()) {
          this.languageController.closeLanguageMobileMenu();
        }
        this.shadowForSigningSection();
      },
      mobileSigningSectionType: this.mobileSigningSectionType,
    });

    const userProfileData = initParams?.userProfileData;
    if (!HeaderController.userProfileDataFetched) {
      HeaderController.userProfileData = userProfileData;

      HeaderController.userProfileDataFetched = true;
    }

    if (!HeaderController.userBrandMakerFetched) {
      const userHaveBrandsOrLogos = initParams?.userHaveBrandsOrLogos;
      HeaderController.userBrandMakerData = { doesUserHaveBrandsOrLogos: userHaveBrandsOrLogos ?? false };
      HeaderController.userBrandMakerFetched = true;
    }

    if (userProfileData) {
      this.renderUserProfileInfo(userProfileData);
    } else {
      this.renderSigningSection();
    }
    this.renderMobileSignUpButtonInTopMenu();

    this.languageController.init({
      onLanguageButtonClick: () => {
        this.shadowForSigningSection();
        this.mainMenuController.collapseOpenedMenu();
      },
    });

    this.menuToggleController.init({
      onOpen: () => {
        this.overlayController.show();
        this.doHeaderInFrontOfCookieBanner();
      },
      onHide: () => {
        this.overlayController.hide();
        this.doCookieBannerInFrontOfHeader();
      },
    });

    this.overlayController.init({ onClick: this.handleOverlayClick });

    const isNonStickyMarketingHeaderSpecOn =
      this.experiments['specs.logo-builder.client.NonStickyMarketingHeader'] === 'true';
    const shouldMakerHeaderSticky = this.config.isSticky() && !isNonStickyMarketingHeaderSpecOn;
    if (shouldMakerHeaderSticky) {
      this.doHeaderSticky();
    }

    if (this.config.isWixSite()) {
      this.moveHeaderToTop();
    }

    if (this.config.isSupportSite()) {
      this.doHeaderWithoutHeight();
    }

    this.handleOutsideClick();
    this.handleClickOnLogoMakerHref();
    this.handleKeyboardEvents();
    this.disableFocusForMouseEvents();
    this.skipButtonController.init();
  }

  private getHrefOverride = () => {
    const language = this.config.getLanguage();
    return language === 'en' ? 'http://manage.wix.com/logo/maker/esh' : `http://manage.wix.com/logo/maker/esh/welcome`;
  };

  private renderMobileSignUpButtonInTopMenu() {
    const button = this.signingButtonController.render({
      signingType: SigningType.SignUp,
      translationKey: 'homepage_menu_get_started',
      className: s.signUpButton,
      biName: 'get_started',
      isMobile: true,
      hrefOverride: this.getHrefOverride(),
    });
    this.elements.signUpButtonContainer.insertAdjacentElement('afterbegin', button);
  }

  private getTypedBusinessName = () => {
    return document.getElementsByTagName('input')[0]?.value ?? null;
  };

  private handleClickOnLogoMakerHref = () => {
    const logoMakerHrefElements = document.querySelectorAll(
      '[href="http://manage.wix.com/logo/maker/esh"]',
    ) as NodeListOf<HTMLElement>;
    logoMakerHrefElements.forEach(logoMakerHrefElement => {
      logoMakerHrefElement.onclick = e => {
        e.preventDefault();
        const businessName = this.getTypedBusinessName();
        goToLogoMaker(this.config.getLanguage(), businessName);
      };
    });
  };

  private handleOutsideClick = () => {
    document.addEventListener('mousedown', event => {
      const { header } = this.elements;
      if (!header.contains(event.target as Node)) {
        this.mainMenuController.collapseOpenedMenu();
        this.userMenuController?.collapseMenu();
      }
    });
  };

  private renderSigningSection({ desktopOnly }: { desktopOnly?: boolean } = {}) {
    const signUpTranslationKey = 'homepage_menu_get_started';
    const signUpBiName = 'get_started';

    const signingSectionElements = this.signingSectionController.renderLogInAndSignUp({
      signUpTranslationKey,
      signUpBiName,
      desktopOnly,
    });
    this.mobileSigningSectionType = desktopOnly ? SigningSectionType.EMPTY : SigningSectionType.TWO_BUTTONS;
    const shouldRenderGoogleOT = !this.config.isMobile();
    const shouldRenderStickyGOT = this.experiments['specs.logo-builder.client.StickyMarketingGOT'] === 'true';
    if (shouldRenderGoogleOT) {
      renderGoogleOT(shouldRenderStickyGOT);
    }

    const menuItem = this.mainMenuController.createWrappedMenuItem({
      className: this.signingSectionController.containerClassName,
      children: signingSectionElements,
    });
    this.mainMenuController.pushItem(menuItem);
  }

  private async renderUserProfileInfo(userProfileData: UserProfileData) {
    document.body.classList.add('logged_in');
    const userImage = await this.dataFetcher.fetchUserProfileImage();
    const shouldRenderGoToMyBrands = this.experiments['specs.logo-builder.client.WithMyBrandsPage'] === 'true';
    const shouldRenderGoToBrandStudio =
      HeaderController.userBrandMakerData?.doesUserHaveBrandsOrLogos && !shouldRenderGoToMyBrands;
    const userMenuStructure = this.headerStructure.userMenu.filter(item => {
      if (!shouldRenderGoToBrandStudio) {
        return item.text !== goToBrandStudioDesktopAccountMenuItem.text;
      } else if (!shouldRenderGoToMyBrands) {
        return item.text !== goToMyBrandsDesktopAccountMenuItem.text;
      } else {
        return true;
      }
    });
    this.userMenuController = new UserMenuController({
      biLogger: this.biLogger,
      config: this.config,
      userProfileData,
      userImage,
      t: this.t,
      userMenuStructure,
    });
    const { desktop, mobile } = this.userMenuController.renderUserMenu({
      onOpen: () => this.mainMenuController.collapseOpenedMenu(),
      onLanguageButtonClick: () =>
        this.languageController.openLanguagePopup(() => this.userMenuController?.getLanguageButtonElement().focus()),
    });

    this.mainMenuController.pushItem(desktop);
    this.mainMenuController.unshiftElement(mobile);
  }

  private renderMySitesButton() {
    const mySiteMenuItem = this.mainMenuController.createMenuItem({ item: this.headerStructure.mySites, t: this.t });
    this.mainMenuController.unshiftElement(mySiteMenuItem.getMainElement());
    mySiteMenuItem.initListeners({ onClickItemWithSubMenu: this.mainMenuController.onClickItemWithSubMenu });
  }

  private handleOverlayClick = (e: MouseEvent) => {
    if (e.target === this.overlayController.element) {
      this.mainMenuController.collapseOpenedMenu();
      this.userMenuController?.collapseMenu();
      this.menuToggleController.closeMenu();
      this.overlayController.hide();
    }
  };

  shadowForSigningSection = () => {
    if (!this.isDesktop() && !this.isLoggedIn()) {
      setTimeout(() => {
        const showShadow = this.mainMenuController.isMenuHasScrollBar();
        this.signingSectionController.toggleShadowDisplaying(showShadow);
      }, 50);
    }
  };

  disableFocusForMouseEvents() {
    const { header } = this.elements;
    window.addEventListener('keydown', this.handleFirstTab);
    window.addEventListener('mousedown', () => {
      setTimeout(() => {
        if (header.contains(document.activeElement)) {
          // @ts-expect-error
          document.activeElement.blur();
        }
      }, 0);
    });
  }

  handleFirstTab = (e: KeyboardEvent) => {
    if (e.keyCode === 9) {
      document.body.classList.add('focused');

      window.removeEventListener('keydown', this.handleFirstTab);
      window.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  };

  handleMouseDownOnce = () => {
    document.body.classList.remove('focused');

    window.removeEventListener('mousedown', this.handleMouseDownOnce);
    window.addEventListener('keydown', this.handleFirstTab);
  };

  handleKeyboardEvents = () => {
    window.addEventListener('keyup', this.handleEscapeButton);
  };

  handleEscapeButton = (e: KeyboardEvent) => {
    const isEscapePressed = e.key === 'Escape' || e.key === 'Esc';
    if (isEscapePressed) {
      if (this.userMenuController?.isExpanded() && !this.languageController.isOpened()) {
        this.userMenuController?.collapseMenu();
        this.userMenuController?.getToggleButtonElement().focus();
      }

      if (this.languageController.isOpened()) {
        this.languageController.closePopup();
      }
    }
  };

  doHeaderSticky() {
    this.elements.container.classList.add(s.fixed);
  }

  moveHeaderToTop() {
    document.body.insertBefore(this.elements.header, document.body.firstChild);
  }

  doHeaderWithoutHeight() {
    this.elements.header.classList.add(s.zeroHeight);
  }

  // we can not change default z-index because it can break design in tempates page
  doHeaderInFrontOfCookieBanner() {
    this.elements.header.classList.add(s.inFrontOfCookieBanner);
  }

  doCookieBannerInFrontOfHeader() {
    this.elements.header.classList.remove(s.inFrontOfCookieBanner);
  }

  isLoggedIn = () => {
    return document.body.classList.contains('logged_in');
  };
}
