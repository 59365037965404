import { Messages } from './translate';
import { Config } from './Config';
import { HttpClient } from '@wix/http-client';

enum ENV {
  Local = 'local',
  Production = 'prod',
}

const API = {
  userProfile: {
    [ENV.Production]: 'https://www.wix.com/_api/wix-html-login-webapp/user/getUserDetails',
    [ENV.Local]: 'http://localhost:3000/getUserDetails',
  },
  profileImage: {
    [ENV.Production]: 'https://www.wix.com/_api/account-info-server/profileimage',
    [ENV.Local]: 'http://localhost:3000/profileimage',
  },
  staticBase: {
    [ENV.Production]: '//static.parastorage.com/services/brandmaker-header-and-footer',
    [ENV.Local]: 'http://localhost:3202',
  },
  listBrands: {
    [ENV.Production]: 'https://www.wix.com/logoapi/logo-builder-server-webapp/v1/brands?page.limit=1&page.offset=0',
    [ENV.Local]: 'http://localhost:3000/brands',
  },
};

export interface UserProfileData {
  userName: string;
}

export interface UserBrandMakerData {
  doesUserHaveBrandsOrLogos: boolean;
}

export class DataFetcher {
  private readonly config: Config;
  private readonly wixHnfVersion: string | undefined;
  private readonly httpClient: HttpClient;

  constructor(config: Config) {
    this.config = config;
    this.wixHnfVersion = window.WIX_HF_VERSION;
    this.httpClient = new HttpClient();
  }

  async fetchTranslations(): Promise<Messages> {
    const response = await this.httpClient.get(
      `${this.getStaticBaseUrl()}/assets/locales/messages_${this.config.getLanguage()}.json`,
    );
    return response.data;
  }

  async fetchUserBrandsList() {
    const apiUrl = API.listBrands[this.getEnv()];
    const response = await this.httpClient.get(apiUrl);
    return response.data;
  }

  async fetchUserProfileData(): Promise<UserProfileData | null> {
    const apiUrl = API.userProfile[this.getEnv()];
    const response = await this.httpClient.get(apiUrl);
    return response.data.payload;
  }

  async fetchUserProfileImage() {
    const defaultProfileImage = `${this.getStaticBaseUrl()}/assets/default-profile-image.svg`;
    try {
      const apiUrl = API.profileImage[this.getEnv()];
      const { data } = await this.httpClient.get(apiUrl);
      const profileImage = data.profileImage;
      return profileImage === '' ? defaultProfileImage : profileImage;
    } catch (e) {
      return defaultProfileImage;
    }
  }

  private getStaticBaseUrl() {
    if (this.getEnv() === ENV.Production && this.wixHnfVersion) {
      return `${API.staticBase[ENV.Production]}/${this.wixHnfVersion}`;
    } else {
      return API.staticBase[ENV.Local];
    }
  }

  private getEnv(): ENV {
    return window.WIX_HF_DEBUG ? ENV.Local : ENV.Production;
  }
}
