import ExperimentsManager, { ExperimentsProps as ExperimentsManagerOptions } from '@wix/wix-experiments';

export type Experiments = {
  'specs.logo-builder.client.FormAnLLC.HP': 'true' | 'false';
  'specs.logo-builder.client.StickyMarketingGOT': 'true' | 'false';
  'specs.logo-builder.client.WithMyBrandsPage': 'true' | 'false';
  'specs.logo-builder.client.NonStickyMarketingHeader': 'true' | 'false';
};

export const experimentFallbacks: Experiments = {
  'specs.logo-builder.client.FormAnLLC.HP': 'false',
  'specs.logo-builder.client.NonStickyMarketingHeader': 'false',
  'specs.logo-builder.client.StickyMarketingGOT': 'false',
  'specs.logo-builder.client.WithMyBrandsPage': 'true',
};

export const loadExperiments = async (): Promise<Experiments> => {
  const experimentsManager = createExperimentManager();
  conduct(experimentsManager, 'specs.logo-builder.client.FormAnLLC.HP');
  conduct(experimentsManager, 'specs.logo-builder.client.NonStickyMarketingHeader');
  conduct(experimentsManager, 'specs.logo-builder.client.StickyMarketingGOT');
  conduct(experimentsManager, 'specs.logo-builder.client.WithMyBrandsPage');
  return experimentsManager.ready().then(() => experimentsManager.all() as Experiments);
};

// eslint-disable-next-line no-unused-vars
const conduct = (experimentsManager: ExperimentsManager, spec: keyof Experiments) => {
  experimentsManager.conduct(spec, experimentFallbacks[spec]);
};

const createExperimentManager = (optionsOverrides: ExperimentsManagerOptions = {}): ExperimentsManager => {
  const defaultOptions: ExperimentsManagerOptions =
    window.location.origin === 'https://status.wix.com'
      ? { baseUrl: 'https://manage.wix.com' }
      : { useNewApi: window.WIX_HF_DEBUG };

  return new ExperimentsManager({ ...defaultOptions, ...optionsOverrides });
};
