import s from './UserMenu.scss';
import { Config } from '../../modules/Config';
import { UserProfileData } from '../../modules/DataFetcher';
import { NavigationItem, UserMenuStructure } from '../../data/types';
import { Translator } from '../../modules/translate';
import { MenuItemWrapper } from '../MenuItemWrapper/MenuItemWrapper';
import { DataHook } from '../../modules/dataHooks';
import { LanguagesIcon } from '../LanguageIcon/LanguageIcon';
import { getLanguageFullName, SupportedLanguage } from '../../data/languages';
import { DropdownArrow } from '../DropdownArrow/DropdownArrow';

export interface UserMenuDesktopProps {
  config: Config;
  userProfileData: UserProfileData;
  userImage: string;
  userMenuStructure: UserMenuStructure;
  t: Translator;
}

interface UserMenuDesktopLanguageButtonProps {
  language: SupportedLanguage;
  item: NavigationItem;
  t: Translator;
}

export const userProfileButtonId = 'main-menu-item-user-profile';

const UserMenuLanguageButton = ({ language, item, t }: UserMenuDesktopLanguageButtonProps) => `
<li class="${s.userSubMenuItem}">
  <button
    class="${s.link} ${s.languageButton}"
    data-bi-name="${item.name}"
    aria-label="${t('language_picker_open_button')}"
    aria-haspopup="true"
  >
    ${LanguagesIcon(s.iconLanguages)}
    <span class="${s.iconText}">${getLanguageFullName(language)}</span>
  </button>
</li>
`;

export const UserMenuDesktop = ({
  t,
  userImage,
  userProfileData: { userName },
  userMenuStructure,
  config,
}: UserMenuDesktopProps) => {
  return `
    ${MenuItemWrapper({
      className: s.container,
    })(`
    <div class="${s.buttonWrapper}">
      <button
        id="${userProfileButtonId}"
        class="${s.button}"
        title="${userName}"
        aria-label="${t('user_profile_aria_label')}"
        data-toggle="collapse"
        aria-expanded="false"
        data-bi-row="first"
        data-bi-name="account_name"
        data-hook="${DataHook.AccountNameButton}"
      >
        <img class="${s.image}" src="${userImage}" alt="${t('profile_image')}" />
      </button>
      <div class="${s.userName}">
      ${userName}</div>
      ${DropdownArrow({ className: s.arrow })}
    </div>
    <div
        class="${s.userSubMenuContainer} items-${userMenuStructure.length}"
        role="region"
        aria-labelledby="${userProfileButtonId}"
      >
        <ul
          class="${s.userSubMenu}"
          data-hook="wix-header-user-sub-menu"
        >
          ${userMenuStructure
            .map((item, index) => {
              const language = config.getLanguage();

              if (item.name === 'language') {
                return UserMenuLanguageButton({ item, language, t });
              }
              if (item.href === 'https://www.wix.com/signout') {
                item.href += `?redirectTo=${window.location.href}`;
              }
              return `
             <li class="${s.userSubMenuItem}">
                <a
                  class="${s.link}"
                  ${item.target ? `target="${item.target}"` : ''}
                  href="${item.href}"
                  data-bi-name="${item.name}"
                  data-bi-row="second"
                >
                  ${t(item.text)}
                </a>
              </li>
          `;
            })
            .join('')}
        </ul>
    </div>
`)}`;
};
