import {
  HeaderStructure,
  LanguageItem,
  MultiLangHeaderStructure,
  MultiLangNavigationItem,
  NavigationItem,
  Separator,
  SEPARATOR,
} from './types';
import { languageData, SupportedLanguage } from './languages';
import { headerStructure, goToBrandStudioMobileMenuItem, goToMyBrandsMobileMenuItem } from './headerStructure';
import { Config } from '../modules/Config';
import { experimentFallbacks, Experiments } from '../experiments';

type Environment = {
  language: SupportedLanguage;
  experiments: Experiments;
  ssr?: boolean;
};

type StructureOptions = {
  isUserLoggedIn?: boolean;
  userHaveBrandsOrLogos?: boolean;
};

export class StructureBuilder {
  private readonly headerStructure: MultiLangHeaderStructure = headerStructure;
  private readonly environment: Environment;

  constructor(private readonly config: Config, environmentOverrides?: Partial<Environment>) {
    this.environment = {
      language: config.getLanguage(),
      experiments: experimentFallbacks,
      ...environmentOverrides,
    };
  }

  getHeaderStructure(redirectToLinks?: Record<string, string>, options?: StructureOptions): HeaderStructure {
    const { config } = this;
    const isUserLoggedIn = options?.isUserLoggedIn;
    const userHaveBrandsOrLogos = options?.userHaveBrandsOrLogos;

    const shouldRenderGoToMyBrandsInMobile =
      isUserLoggedIn && this.environment.experiments['specs.logo-builder.client.WithMyBrandsPage'] === 'true';
    const shouldRenderGoToBrandStudioInMobile =
      isUserLoggedIn && userHaveBrandsOrLogos && !shouldRenderGoToMyBrandsInMobile;

    const languagePickerMenu = [...config.getLanguagePickerList()]
      .filter(lang => languageData[lang])
      .map(lang => {
        const item: LanguageItem = { name: lang, text: languageData[lang] };
        const languageRedirectToLink = redirectToLinks && redirectToLinks[lang];
        if (languageRedirectToLink) {
          item.href = lang === 'he' ? languageRedirectToLink : this._setLanguageUrl(lang, languageRedirectToLink);
        }
        return item;
      });
    const nav = this.headerStructure.nav.reduce<(NavigationItem | Separator)[]>((acc, item) => {
      if (item === SEPARATOR) {
        acc.push(item);
      } else {
        if (!shouldRenderGoToMyBrandsInMobile && item.text === goToMyBrandsMobileMenuItem.text) {
          return acc;
        } else if (!shouldRenderGoToBrandStudioInMobile && item.text === goToBrandStudioMobileMenuItem.text) {
          return acc;
        } else if (
          item.experiments &&
          item.experiments['specs.logo-builder.client.FormAnLLC.HP'] !==
            this.environment.experiments['specs.logo-builder.client.FormAnLLC.HP']
        ) {
          return acc;
        }

        const itemController = new MultiLangNavigationItemController(item, this.environment);
        if (itemController.supportsEnvironment) {
          acc.push(itemController.convertToNavigationItem());
        }
      }
      return acc;
    }, []);

    return {
      ...this.headerStructure,
      languagePickerMenu,
      nav,
    };
  }

  private _setLanguageUrl = (language: string, redirectTo: string) => {
    return `https://users.wix.com/wix-users/auth/setLanguage?language=${language}&redirectTo=${encodeURIComponent(
      redirectTo,
    )}`;
  };
}

class MultiLangNavigationItemController {
  constructor(private readonly item: MultiLangNavigationItem, private readonly environment: Environment) {}

  get supportsEnvironment() {
    // TODO: extract into supportEnv or smth
    if (this.environment.ssr && this.item.omitInSsr) {
      return false;
    }
    return this.supportsLanguage && this.supportsScreenWidth && this.supportsExperiments;
  }

  private get supportsLanguage() {
    const { lang } = this.item;
    return !lang || lang.includes(this.environment.language);
  }

  private get supportsScreenWidth() {
    const { item, environment } = this;
    if (environment.ssr || !item.screenWidths) {
      return true;
    }

    const currentWidth = window.innerWidth;
    return item.screenWidths.some(([from, to]) => currentWidth >= from && currentWidth <= to);
  }

  private get supportsExperiments() {
    const { item, environment } = this;
    if (!item.experiments) {
      return true;
    }

    return Object.entries(item.experiments).every(
      ([spec, result]) => environment.experiments[spec as keyof Experiments] === result,
    );
  }

  convertToNavigationItem(): NavigationItem {
    const subs = this.item.subs?.reduce<NavigationItem[]>((acc, subItem) => {
      const subItemController = new MultiLangNavigationItemController(subItem, this.environment);
      if (subItemController.supportsEnvironment) {
        acc.push(subItemController.convertToNavigationItem());
      }
      return acc;
    }, []);

    const sideBlockInfo = this.item.sideBlockInfo?.reduce<NavigationItem[]>((acc, subItem) => {
      const subItemController = new MultiLangNavigationItemController(subItem, this.environment);
      if (subItemController.supportsEnvironment) {
        acc.push(subItemController.convertToNavigationItem());
      }
      return acc;
    }, []);

    let sideBlockInfoButton: NavigationItem | undefined;
    if (this.item.sideBlockInfoButton) {
      const controller = new MultiLangNavigationItemController(this.item.sideBlockInfoButton, this.environment);
      if (controller.supportsEnvironment) {
        sideBlockInfoButton = controller.convertToNavigationItem();
      }
    }

    const href = this.resolveHref();

    return {
      ...this.item,
      href,
      subs,
      sideBlockInfo,
      sideBlockInfoButton,
    };
  }

  private resolveHref() {
    const hrefOrMap = this.item.href;

    if (typeof hrefOrMap === 'string' || !hrefOrMap) {
      return hrefOrMap;
    }
    return hrefOrMap[this.environment.language] || 'no-link';
  }
}
