import s from './Logo.scss';
import { SupportedLanguage } from '../../data/languages';

interface LogoProps {
  t(key: string): string;
  language: SupportedLanguage;
}

export const Logo = ({ t, language }: LogoProps) => {
  return `
    <div class="${s.logo}">
      <a
        class="${s.link}"
        aria-label="${t('wix_logo_aria_label')}"
        title="Wix.com"
        href="https://${language === 'en' ? 'www' : language}.wix.com"
     ></a>
    </div>
  `;
};
