import s from './SkipButton.scss';

interface SkipButtonProps {
  text: string;
  dataHook?: string;
  className?: string;
}

export const SkipButton = ({ text, dataHook, className }: SkipButtonProps) => `
  <button 
    ${dataHook ? `data-hook=${dataHook}` : ''}
    tabindex="-1"
    class='${s.button} ${className || ''}'
   >
    ${text}
   </button>
`;
