import { isSupportedLanguage, languagesInLanguagePicker, SupportedLanguage } from '../data/languages';

export type HeaderFooterParams = Partial<ConfigData>;

interface ConfigData {
  sticky: boolean;
  headerWrapper: HTMLElement | null;
  isSupportSite: boolean; // field added for integration with support.wix.com. Should be fixed after experiment win.
  languageRedirectUrl: LanguageRedirectUrl | undefined;
  hideMainLinksOnDesktop: boolean;
  language: string;
  postLogin?: string;
  postSignUp?: string;
  postSignOut?: string;
  languagePickerList: string[];
  isWixSite: boolean;
  mainContentSelector?: string;
  isMobile?: boolean;
}

export class Config {
  private data: ConfigData = {
    sticky: true,
    headerWrapper: null,
    isSupportSite: false,
    hideMainLinksOnDesktop: false,
    languageRedirectUrl: undefined,
    language: 'en',
    languagePickerList: languagesInLanguagePicker,
    isWixSite: false,
  };

  constructor(params: HeaderFooterParams = {}) {
    Object.assign(this.data, params);
    if (!isSupportedLanguage(params.language)) {
      console.warn(`Wix Header 2021 doesn't support language "${params.language}"!!!`);
      this.data.language = 'en';
    }
    this.data.isMobile = params.isMobile;
  }

  public isSticky = () => this.data.sticky;

  public getLanguage = () => this.data.language as SupportedLanguage;

  public getPostLogin = () => this.data.postLogin;

  public getPostSignUp = () => this.data.postSignUp;

  public getPostSignOut = () => this.data.postSignOut;

  public getHeaderWrapper = () => this.data.headerWrapper;

  public isSupportSite = () => this.data.isSupportSite;

  public getVersion = () => window.WIX_HF_VERSION;

  public shouldDesktopLinkBeHidden = () => this.data.hideMainLinksOnDesktop;

  public getLanguagePickerList = () => this.data.languagePickerList;

  public isWixSite = () => this.data.isWixSite;

  public isMobile = () => this.data.isMobile;

  public getMainContentSelector = () => this.data.mainContentSelector;
}

export interface HFConfig {}

export type LanguageRedirectUrl = (param: { language: string }) => string;
