import { BaseElementController } from '../../modules/BaseElementController';
import { Separator } from '../Separator/Separator';
import {
  RenderParams as RenderSigningButtonParams,
  SigningButtonController,
  SigningButtonControllerParams,
} from './SigningButtonController';
import { SigningType } from './common';

import s from './SigningSection.scss';
import { Config } from '../../modules/Config';

type RenderLogInAndSignUpParams = {
  desktopOnly?: boolean;
  signUpTranslationKey: string;
  signUpBiName: string;
};

export class SigningSectionController extends BaseElementController {
  private readonly signingButtonController: SigningButtonController;
  private readonly containerClassNames: string[] = [s.signingSectionContainer];
  private readonly config: Config;

  constructor({ biLogger, config, t }: SigningButtonControllerParams) {
    super();
    this.signingButtonController = new SigningButtonController({ biLogger, config, t });
    this.config = config;
  }

  get containerClassName(): string {
    return this.containerClassNames.join(' ');
  }

  private renderDesktopAndMobile = (params: Omit<RenderSigningButtonParams, 'isMobile'>) => {
    const desktopButton = this.signingButtonController.render({
      ...params,
      className: `${params.className} ${s.desktopOnly}`,
    });
    const mobileButton = this.signingButtonController.render({
      ...params,
      className: `${params.className} ${s.mobileOnly}`,
      isMobile: true,
    });

    return [desktopButton, mobileButton];
  };

  private getHrefOverride = () => {
    const language = this.config.getLanguage();
    console.log(`Language: ${language}`);
    return language === 'en' ? 'http://manage.wix.com/logo/maker/esh' : `http://manage.wix.com/logo/maker/esh/welcome`;
  };

  renderSignIn(): Element[] {
    return this.renderDesktopAndMobile({
      signingType: SigningType.SignIn,
      translationKey: 'menu_sign_in',
      className: `${s.primaryButton} ${s.signInButton}`,
      biName: 'sign_in',
      // DO NOT CHANGE: this specific data-hook value is used on https://support.wix.com page
      dataHook: 'login',
    });
  }

  renderLogInAndSignUp({ signUpTranslationKey, signUpBiName, desktopOnly }: RenderLogInAndSignUpParams): Element[] {
    this.containerClassNames.push(s.twoButtons);
    if (desktopOnly) {
      this.containerClassNames.push(s.desktopOnly);
    }

    const separator = this.createElementFromHTML(Separator({ className: s.separator }));
    const logInButtons = this.renderDesktopAndMobile({
      signingType: SigningType.LogIn,
      translationKey: 'menu_log_in',
      className: s.secondaryButton,
      biName: 'log_in',
      dataHook: 'log-in-button',
    });
    const signUpButtons = this.renderDesktopAndMobile({
      signingType: SigningType.SignUp,
      translationKey: signUpTranslationKey,
      className: `${s.primaryButton} ${s.signUpButton}`,
      biName: signUpBiName,
      dataHook: 'sign-up-button',
      hrefOverride: this.getHrefOverride(),
    });

    return [separator, ...logInButtons, ...signUpButtons];
  }

  toggleShadowDisplaying(show: boolean) {
    const container = this.$<HTMLDivElement>(`.${s.signingSectionContainer}`)!;
    container.classList[show ? 'add' : 'remove'](s.hasShadow);
  }
}
