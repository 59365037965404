import { Config, HeaderFooterParams } from './modules/Config';
import { DataFetcher } from './modules/DataFetcher';
import { HeaderFooterController } from './modules/HeaderFooterController';
import { loadExperiments } from './experiments';
import { BiLogger } from './modules/BiLogger';
import { MOBILE_MAX_WIDTH } from './modules/breakpoints';

export async function initHeaderAndFooter2020(params: HeaderFooterParams) {
  if (typeof window === 'undefined') {
    console.warn('Wix Header 2021: window is not defined');
    return;
  }
  if (!window.WIX_HF_INITIALIZED) {
    window.WIX_HF_INITIALIZED = true;
    window.WIX_HF_START_TIME = new Date().getTime();
    const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
    const config = new Config({ ...params, isMobile });
    const language = config.getLanguage();

    const biLogger = new BiLogger({ version: config.getVersion(), language });
    biLogger.startLoading();

    const dataFetcher = new DataFetcher(config);
    const [messages, experiments] = await Promise.all([dataFetcher.fetchTranslations(), loadExperiments()]);

    const headerFooterController = new HeaderFooterController({ config, messages, dataFetcher, experiments, biLogger });

    await headerFooterController.init();
    biLogger.loaded();
  } else {
    console.warn('Wix Header 2021 has already been initialized');
  }
}

window.initHeaderAndFooter2020 = initHeaderAndFooter2020;
