import { Experiments } from '../experiments';
import { BiLogger } from './BiLogger';
import { Config } from './Config';
import { DataFetcher } from './DataFetcher';

const navigateWindow = (url: string, isNewWindow: boolean): void => {
  if (isNewWindow) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }
};

const navigateAndSetWixLanguage = (redirectUrl: string, language: string) => {
  const changeLanguageUrl = `https://users.wix.com/wix-users/auth/setLanguage?language=${language}&redirectTo=${encodeURIComponent(
    redirectUrl,
  )}`;
  navigateWindow(changeLanguageUrl, false);
};

export const goToLogoMaker = (language?: string, companyName?: string): void => {
  const companyNameQuery = companyName ? `?origin=marketingHomePage&companyName=${companyName}` : '';
  const logoMakerUrl = `https://manage.wix.com/logo/maker/esh${companyNameQuery}`;
  if (language) {
    navigateAndSetWixLanguage(logoMakerUrl, language);
  } else {
    navigateWindow(logoMakerUrl, false);
  }
};

export const changeCurrentPageLanguage = (language: string): void => {
  navigateAndSetWixLanguage(window.location.href, language);
};

const isBusinessNameTyped = (): boolean => {
  return !!document.getElementsByTagName('input')[0]?.value ?? false;
};

export const redirectToEshIfNeeded = async (
  config: Config,
  dataFetcher: DataFetcher,
  biLogger: BiLogger,
  experiments: Experiments,
) => {
  const userProfileData = await dataFetcher.fetchUserProfileData().catch(() => null);
  if (userProfileData) {
    const { brands } = await dataFetcher.fetchUserBrandsList();
    const shouldStopRedirect = isBusinessNameTyped();
    console.log(`Redirect?: ${shouldStopRedirect}`);
    if (!shouldStopRedirect && brands && brands[0]) {
      biLogger.redirectToOnboarding();
      goToLogoMaker(config.getLanguage());
    }
  }
};
