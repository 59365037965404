/* global google*/

export const renderGoogleOT = shouldRenderStickyGOT => {
  window.handleCredentialResponse = response => {
    const redirectUrl = encodeURIComponent('https://manage.wix.com/logo/maker/esh/');
    console.log('in GOT callback');
    const url = `https://users.wix.com/signin?color=LOGO&redirectTo=${redirectUrl}#state=googleOT&id_token=${response.credential}`;
    window.location = url;
  };

  const googleOTScript = document.createElement('script');
  googleOTScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
  googleOTScript.setAttribute('async', '');
  document.head.appendChild(googleOTScript);

  const container = document.createElement('div');
  container.setAttribute('id', 'g_id_onload');
  container.setAttribute('data-client_id', '352188552355.apps.googleusercontent.com');
  container.setAttribute('data-callback', 'handleCredentialResponse');
  container.setAttribute('data-cancel_on_tap_outside', 'false');
  container.setAttribute('data-prompt_parent_id', 'g_id_onload');
  const position = shouldRenderStickyGOT ? 'fixed' : 'absolute';
  container.setAttribute('style', `position: ${position} ;right:400px;top:55px;width:0;height:0;`);
  document.body.appendChild(container);

  let cacheKillerCounter = 0;

  function getCacheKiller() {
    return new Date().getTime() + cacheKillerCounter++;
  }
  const body = '&' + encodeURIComponent('app_url=wix.com');

  console.log('initialized GOT');

  googleOTScript.onload = function () {
    google.accounts.id.prompt(notification => {
      if (notification.isDisplayed()) {
        fetch(`https://frog.wix.com/hls2?src=6&evid=186&app_url=wix.com&_=${getCacheKiller()}`, {
          method: 'post',
          mode: 'no-cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body,
        });
      }
    });
  };
};
