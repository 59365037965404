import { Logo } from '../Logo/Logo';
import s from './Header.scss';
import { Translator } from '../../modules/translate';
import { HeaderStructure } from '../../data/types';
import { MenuToggle } from '../MenuToggle/MenuToggle';
import { Config } from '../../modules/Config';
import { MainMenu } from '../MainMenu/MainMenu';
import { Overlay } from '../Overlay/Overlay';
import { SkipButton } from '../SkipButton/SkipButton';
import { DataHook } from '../../modules/dataHooks';

interface HeaderProps {
  t: Translator;
  structure: HeaderStructure;
  config: Config;
}

export const Header = ({ t, structure, config }: HeaderProps) => {
  return `
    <header class="${s.container}" id="WIX_HEADER" data-wix-header="hf2021">
      <style>
        #WIX_HEADER {
          visibility: hidden;
          height: 54px;
        }
        @media (min-width: 768px) {
          #WIX_HEADER {
            height: 72px;
          }
        }
      </style>
      <div class="${s.header}">
        ${SkipButton({ text: t('skip_to_main_content'), dataHook: DataHook.SkipButton })}
        ${Logo({ t, language: config.getLanguage() })}
        <div class="${s.signUpButtonContainer}"></div>
        ${MenuToggle({ t })}
        ${Overlay()}
        ${MainMenu({ t, navigationList: structure.nav, config })}
      </div>
    </header>
  `;
};
