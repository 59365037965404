import { DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN, DESKTOP_MORE_DROPDOWN, MOBILE } from './screen-widths';
import { MultiLangHeaderStructure, MultiLangNavigationItem, NavigationItem, SEPARATOR } from './types';

const logoMakerNavigationItem: MultiLangNavigationItem = {
  text: 'homepage_logo_maker_title',
  href: {
    en: 'https://www.wix.com/logo/maker',
    es: 'https://es.wix.com/logo/maker',
    pt: 'https://pt.wix.com/logo/criar-logo',
    fr: 'https://fr.wix.com/logo/creer-logo',
    de: 'https://de.wix.com/logo/erstellen',
    ja: 'https://ja.wix.com/logo/maker',
    ko: 'https://ko.wix.com/logo/maker',
    tr: 'https://tr.wix.com/logo/maker',
    nl: 'https://nl.wix.com/logo/maker',
    it: 'https://it.wix.com/logo/creare-logo',
    pl: 'https://pl.wix.com/logo/maker',
    no: 'https://no.wix.com/logo/maker',
    sv: 'https://sv.wix.com/logo/maker',
    da: 'https://da.wix.com/logo/maker',
    cs: 'https://cs.wix.com/logo/maker',
    ru: 'https://ru.wix.com/logo/maker',
  },
  name: 'homepage_logo_maker_title',
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl', 'no', 'sv', 'da', 'cs', 'ru'],
};

const topLogoIndustries: MultiLangNavigationItem = {
  text: 'homepage_logo_industries_title',
  name: 'homepage_logo_industries_title',
  subs: [
    {
      text: 'homepage_band_logos_title',
      href: {
        de: 'https://de.wix.com/logo/erstellen/category/music/band',
        pt: 'https://pt.wix.com/logo/criar-logo/category/music/band',
        ja: 'https://ja.wix.com/logo/maker/category/music/band',
      },
      name: 'homepage_band_logos_title',
      lang: ['de', 'pt', 'ja'],
    },
    {
      text: 'homepage_beauty_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/category/beauty',
        es: 'https://es.wix.com/logo/maker/category/beauty',
        pt: 'https://pt.wix.com/logo/criar-logo/category/beauty',
        de: 'https://de.wix.com/logo/erstellen/category/beauty',
      },
      name: 'homepage_beauty_logos_title',
      lang: ['en', 'es', 'pt', 'de'],
    },
    {
      text: 'homepage_cleaning_logos_title',
      href: {
        nl: 'https://nl.wix.com/logo/ideas/category/cleaning',
        it: 'https://it.wix.com/logo/ideas/category/cleaning',
      },
      name: 'homepage_cleaning_logos_title',
      lang: ['nl', 'it'],
    },
    {
      text: 'homepage_construction_logos_title',
      href: {
        es: 'https://es.wix.com/logo/maker/category/construction',
        pt: 'https://pt.wix.com/logo/criar-logo/category/construction',
        fr: 'https://fr.wix.com/logo/creer-logo/category/construction',
        tr: 'https://tr.wix.com/logo/maker/category/construction',
        nl: 'https://nl.wix.com/logo/ideas/category/construction',
        it: 'https://it.wix.com/logo/ideas/category/construction',
        pl: 'https://pl.wix.com/logo/ideas/category/construction',
      },
      name: 'homepage_construction_logos_title',
      lang: ['es', 'pt', 'fr', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_fashion_logos_title',
      href: {
        de: 'https://de.wix.com/logo/erstellen/category/fashion',
        pl: 'https://pl.wix.com/logo/ideas/category/fashion',
      },
      name: 'homepage_fashion_logos_title',
      lang: ['de', 'pl'],
    },
    {
      text: 'homepage_fitness_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/category/fitness',
      },
      name: 'homepage_fitness_logos_title',
      lang: ['en'],
    },
    {
      text: 'homepage_food_logos_title',
      href: {
        ja: 'https://ja.wix.com/logo/maker/category/food',
        tr: 'https://tr.wix.com/logo/maker/category/food',
      },
      name: 'homepage_food_logos_title',
      lang: ['ja', 'tr'],
    },
    {
      text: 'homepage_medical_logos_title',
      href: {
        fr: 'https://fr.wix.com/logo/creer-logo/category/medical',
        de: 'https://de.wix.com/logo/erstellen/category/medical',
        ko: 'https://ko.wix.com/logo/maker/category/medical',
      },
      name: 'homepage_medical_logos_title',
      lang: ['fr', 'de', 'ko'],
    },
    {
      text: 'homepage_music_logos_title',
      href: {
        fr: 'https://fr.wix.com/logo/creer-logo/category/music',
        ja: 'https://ja.wix.com/logo/maker/category/music',
      },
      name: 'homepage_music_logos_title',
      lang: ['fr', 'ja'],
    },
    {
      text: 'homepage_photography_logos_title',
      href: {
        ja: 'https://ja.wix.com/logo/maker/category/photography',
        nl: 'https://nl.wix.com/logo/ideas/category/photography',
        pl: 'https://pl.wix.com/logo/ideas/category/photography',
      },
      name: 'homepage_photography_logos_title',
      lang: ['ja', 'nl', 'pl'],
    },

    {
      text: 'homepage_real_estate_logos_title',
      name: 'homepage_real_estate_logos_title',
      lang: ['en', 'es', 'ko', 'tr', 'it'],
      href: {
        en: 'https://www.wix.com/logo/maker/category/real-estate',
        es: 'https://es.wix.com/logo/maker/category/real-estate',
        ko: 'https://ko.wix.com/logo/maker/category/real-estate',
        tr: 'https://tr.wix.com/logo/maker/category/real-estate',
        it: 'https://it.wix.com/logo/ideas/category/real-estate',
      },
    },
    {
      text: 'homepage_restaurants_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/category/food/restaurant',
        es: 'https://es.wix.com/logo/maker/category/food/restaurant',
        pt: 'https://pt.wix.com/logo/criar-logo/category/food/restaurant',
        fr: 'https://fr.wix.com/logo/creer-logo/category/food/restaurant',
        ko: 'https://ko.wix.com/logo/maker/category/food/restaurant',
        tr: 'https://tr.wix.com/logo/maker/category/food/restaurant',
        nl: 'https://nl.wix.com/logo/ideas/category/restaurant',
        it: 'https://it.wix.com/logo/ideas/category/restaurant',
        pl: 'https://pl.wix.com/logo/ideas/category/restaurant',
      },
      name: 'homepage_restaurants_logos_title',
      lang: ['en', 'es', 'pt', 'fr', 'ko', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_sports_logos_title',
      href: {
        pt: 'https://pt.wix.com/logo/criar-logo/category/sports',
        ko: 'https://ko.wix.com/logo/maker/category/sports',
        nl: 'https://nl.wix.com/logo/ideas/category/sports',
        pl: 'https://pl.wix.com/logo/ideas/category/sports',
      },
      name: 'homepage_sports_logos_title',
      lang: ['pt', 'ko', 'nl', 'pl'],
    },
    {
      text: 'homepage_youtube_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/category/youtube',
        es: 'https://es.wix.com/logo/maker/category/youtube',
        fr: 'https://fr.wix.com/logo/creer-logo/category/youtube',
        de: 'https://de.wix.com/logo/erstellen/category/youtube',
        ja: 'https://ja.wix.com/logo/maker/category/youtube',
        ko: 'https://ko.wix.com/logo/maker/category/youtube',
        tr: 'https://tr.wix.com/logo/maker/category/youtube',
        it: 'https://it.wix.com/logo/ideas/category/youtube',
      },
      name: 'homepage_youtube_logos_title',
      lang: ['en', 'es', 'fr', 'de', 'ja', 'ko', 'tr', 'it'],
    },
  ],
  collapsibleIntoMoreDropdown: true,
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
};

const topLogoDesigns: MultiLangNavigationItem = {
  text: 'homepage_top_logo_designs_title',
  name: 'homepage_top_logo_designs_title',
  subs: [
    {
      text: 'homepage_abstract_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/abstract',
        es: 'https://es.wix.com/logo/maker/styles/abstract',
        pt: 'https://pt.wix.com/logo/criar-logo/styles/abstract',
        fr: 'https://fr.wix.com/logo/creer-logo/styles/abstract',
        de: 'https://de.wix.com/logo/erstellen/styles/abstract',
        ja: 'https://ja.wix.com/logo/maker/styles/abstract',
        ko: 'https://ko.wix.com/logo/maker/styles/abstract',
        tr: 'https://tr.wix.com/logo/maker/styles/abstract',
        nl: 'https://nl.wix.com/logo/ideas/styles/abstract',
        it: 'https://it.wix.com/logo/ideas/styles/abstract',
        pl: 'https://pl.wix.com/logo/ideas/styles/abstract',
      },
      name: 'homepage_abstract_logos_title',
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_circle_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/circle',
      },
      name: 'homepage_circle_logos_title',
      lang: ['en'],
    },
    {
      text: 'homepage_elegant_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/elegant',
        es: 'https://es.wix.com/logo/maker/styles/elegant',
        pt: 'https://pt.wix.com/logo/criar-logo/styles/elegant',
        fr: 'https://fr.wix.com/logo/creer-logo/styles/elegant',
        de: 'https://de.wix.com/logo/erstellen/styles/elegant',
        ja: 'https://ja.wix.com/logo/maker/styles/elegant',
        ko: 'https://ko.wix.com/logo/maker/styles/elegant',
        tr: 'https://tr.wix.com/logo/maker/styles/elegant',
        nl: 'https://nl.wix.com/logo/ideas/styles/elegant',
        it: 'https://it.wix.com/logo/ideas/styles/elegant',
        pl: 'https://pl.wix.com/logo/ideas/styles/elegant',
      },
      name: 'homepage_elegant_logos_title',
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_minimalist_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/minimalist',
        es: 'https://es.wix.com/logo/maker/styles/minimalist',
        pt: 'https://pt.wix.com/logo/criar-logo/styles/minimalist',
        fr: 'https://fr.wix.com/logo/creer-logo/styles/minimalist',
        de: 'https://de.wix.com/logo/erstellen/styles/minimalist',
        ja: 'https://ja.wix.com/logo/maker/styles/minimalist',
        ko: 'https://ko.wix.com/logo/maker/styles/minimalist',
        tr: 'https://tr.wix.com/logo/maker/styles/minimalist',
        nl: 'https://nl.wix.com/logo/ideas/styles/minimalist',
        it: 'https://it.wix.com/logo/ideas/styles/minimalist',
        pl: 'https://pl.wix.com/logo/ideas/styles/minimalist',
      },
      name: 'homepage_minimalist_logos_title',
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_modern_logos_title',
      name: 'homepage_modern_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/modern',
        es: 'https://es.wix.com/logo/maker/styles/modern',
        pt: 'https://pt.wix.com/logo/criar-logo/styles/modern',
        fr: 'https://fr.wix.com/logo/creer-logo/styles/modern',
        de: 'https://de.wix.com/logo/erstellen/styles/modern',
        ja: 'https://ja.wix.com/logo/maker/styles/modern',
        ko: 'https://ko.wix.com/logo/maker/styles/modern',
        tr: 'https://tr.wix.com/logo/maker/styles/modern',
        nl: 'https://nl.wix.com/logo/ideas/styles/modern',
        it: 'https://it.wix.com/logo/ideas/styles/modern',
        pl: 'https://pl.wix.com/logo/ideas/styles/modern',
      },
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_retro_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/retro',
        es: 'https://es.wix.com/logo/maker/styles/retro',
        pt: 'https://pt.wix.com/logo/criar-logo/styles/retro',
        fr: 'https://fr.wix.com/logo/creer-logo/styles/retro',
        de: 'https://de.wix.com/logo/erstellen/styles/retro',
        ja: 'https://ja.wix.com/logo/maker/styles/retro',
        ko: 'https://ko.wix.com/logo/maker/styles/retro',
        tr: 'https://tr.wix.com/logo/maker/styles/retro',
        nl: 'https://nl.wix.com/logo/ideas/styles/retro',
        it: 'https://it.wix.com/logo/ideas/styles/retro',
        pl: 'https://pl.wix.com/logo/ideas/styles/retro',
      },
      name: 'homepage_retro_logos_title',
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
    },
    {
      text: 'homepage_text_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/styles/text',
      },
      name: 'homepage_text_logos_title',
      lang: ['en'],
    },
    {
      text: 'homepage_color_logos_title',
      href: {
        en: 'https://www.wix.com/logo/maker/colors',
        es: 'https://es.wix.com/logo/maker/colors',
        pt: 'https://pt.wix.com/logo/criar-logo/colors',
        fr: 'https://fr.wix.com/logo/creer-logo/colors',
        de: 'https://de.wix.com/logo/erstellen/colors',
        ja: 'https://ja.wix.com/logo/maker/colors',
        ko: 'https://ko.wix.com/logo/maker/colors',
        tr: 'https://tr.wix.com/logo/maker/colors',
        nl: 'https://nl.wix.com/logo/ideas/colors',
        it: 'https://it.wix.com/logo/ideas/colors',
        pl: 'https://pl.wix.com/logo/ideas/colors',
      },
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
      name: 'homepage_color_logos_title',
    },
  ],
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
  collapsibleIntoMoreDropdown: true,
};

const logoDesignBlogItem: MultiLangNavigationItem = {
  text: 'homepage_logo_design_blog_title',
  href: {
    en: 'https://www.wix.com/blog',
    es: 'https://es.wix.com/blog',
    pt: 'https://pt.wix.com/blog',
    fr: 'https://fr.wix.com/blog',
    de: 'https://de.wix.com/blog',
    ja: 'https://ja.wix.com/blog',
    ko: 'https://ko.wix.com/blog',
    tr: 'https://tr.wix.com/blog',
    nl: 'https://nl.wix.com/blog',
    it: 'https://it.wix.com/blog',
    pl: 'https://pl.wix.com/blog',
  },
  name: 'homepage_logo_design_blog_title',
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl'],
  collapsibleIntoMoreDropdown: true,
};

const brandingTools: MultiLangNavigationItem = {
  text: 'homepage_branding_tools_title',
  name: 'homepage_branding_tools_title',
  subs: [
    {
      text: 'homepage_bng_title',
      href: {
        en: 'https://www.wix.com/tools/business-name-generator',
      },
      name: 'homepage_bng_title',
      lang: ['en'],
    },
    {
      text: 'homepage_bcm_title',
      name: 'homepage_bcm_title',
      href: {
        en: 'https://www.wix.com/tools/business-card-maker',
        es: 'https://es.wix.com/tools/business-card-maker',
        pt: 'https://pt.wix.com/tools/business-card-maker',
        fr: 'https://fr.wix.com/tools/business-card-maker',
        de: 'https://de.wix.com/tools/business-card-maker',
        ja: 'https://ja.wix.com/tools/business-card-maker',
        ko: 'https://ko.wix.com/tools/business-card-maker',
        tr: 'https://tr.wix.com/tools/business-card-maker',
        nl: 'https://nl.wix.com/tools/business-card-maker',
        it: 'https://it.wix.com/tools/business-card-maker',
        pl: 'https://pl.wix.com/tools/business-card-maker',
        no: 'https://no.wix.com/tools/business-card-maker',
        sv: 'https://sv.wix.com/tools/business-card-maker',
        da: 'https://da.wix.com/tools/business-card-maker',
        cs: 'https://cs.wix.com/tools/business-card-maker',
        ru: 'https://ru.wix.com/tools/business-card-maker',
      },
      lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl', 'no', 'sv', 'da', 'cs', 'ru'],
    },
    {
      text: 'homepage_dng_title',
      href: {
        en: 'https://www.wix.com/tools/domain-name-generator',
      },
      name: 'homepage_dng_title',
      lang: ['en'],
    },
    {
      text: 'homepage_explore_business_tools_title',
      href: {
        en: 'https://www.wix.com/tools',
      },
      name: 'homepage_explore_business_tools_title',
      lang: ['en'],
    },
  ],
};

const hireDesignerItem: MultiLangNavigationItem = {
  text: 'homepage_hire_designer_title',
  href: {
    en: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://www.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    es: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://es.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    pt: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://pt.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    fr: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://fr.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    de: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://de.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    ja: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://ja.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    ko: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://ko.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    tr: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://tr.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    it: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://it.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    pl: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://pl.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    no: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://no.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    sv: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://sv.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    da: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://da.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    cs: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://cs.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
    ru: `https://manage.wix.com/logo/maker/esh/partners?redirectUrl=${encodeURIComponent(
      'https://ru.wix.com/marketplace/brief-auto-match?entry=logo-maker-lp&serviceType=1&innerType=1&origin=top-header',
    )}`,
  },
  name: 'homepage_hire_designer_title',
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl', 'no', 'sv', 'da', 'cs', 'ru'],
};

const formAnLLC: MultiLangNavigationItem = {
  text: 'homepage_form_an_LLC_title',
  href: {
    en: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    es: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    pt: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    fr: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    de: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    ja: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    ko: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    tr: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    it: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    pl: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    no: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    sv: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    da: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    cs: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
    ru: `https://www.wix.com/legalzoom/llc?utm_source=header&utm_campaign=brand_maker_gate`,
  },
  name: 'homepage_form_an_LLC_title',
  experiments: { 'specs.logo-builder.client.FormAnLLC.HP': 'true' },
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl', 'no', 'sv', 'da', 'cs', 'ru'],
};

export const goToBrandStudioMobileMenuItem: MultiLangNavigationItem = {
  text: 'homepage_menu_go_to_brand_studio',
  screenWidths: [MOBILE],
  href: {
    en: 'http://manage.wix.com/logo/maker/esh',
  },
  name: 'see_my_logos',
  lang: ['en'],
};

export const goToMyBrandsMobileMenuItem: MultiLangNavigationItem = {
  text: 'homepage_menu_go_to_my_brands',
  screenWidths: [MOBILE],
  href: {
    en: 'http://manage.wix.com/logo/maker/esh/my-brands',
    es: 'http://manage.wix.com/logo/maker/esh/my-brands',
    pt: 'http://manage.wix.com/logo/maker/esh/my-brands',
    fr: 'http://manage.wix.com/logo/maker/esh/my-brands',
    de: 'http://manage.wix.com/logo/maker/esh/my-brands',
    ja: 'http://manage.wix.com/logo/maker/esh/my-brands',
    ko: 'http://manage.wix.com/logo/maker/esh/my-brands',
    tr: 'http://manage.wix.com/logo/maker/esh/my-brands',
    nl: 'http://manage.wix.com/logo/maker/esh/my-brands',
    it: 'http://manage.wix.com/logo/maker/esh/my-brands',
    pl: 'http://manage.wix.com/logo/maker/esh/my-brands',
    no: 'http://manage.wix.com/logo/maker/esh/my-brands',
    sv: 'http://manage.wix.com/logo/maker/esh/my-brands',
    da: 'http://manage.wix.com/logo/maker/esh/my-brands',
    cs: 'http://manage.wix.com/logo/maker/esh/my-brands',
    ru: 'http://manage.wix.com/logo/maker/esh/my-brands',
  },
  name: 'my_brands',
  lang: ['en', 'es', 'pt', 'fr', 'de', 'ja', 'ko', 'tr', 'nl', 'it', 'pl', 'no', 'sv', 'da', 'cs', 'ru'],
};

export const goToBrandStudioDesktopAccountMenuItem: NavigationItem = {
  text: 'homepage_menu_go_to_brand_studio',
  href: 'http://manage.wix.com/logo/maker/esh',
  name: 'see_my_logos',
};

export const goToMyBrandsDesktopAccountMenuItem: NavigationItem = {
  text: 'homepage_menu_go_to_my_brands',
  href: 'http://manage.wix.com/logo/maker/esh/my-brands',
  name: 'my_brands',
};

const moreDropdown: MultiLangNavigationItem = {
  isMoreDropdown: true,
  screenWidths: [DESKTOP_MORE_DROPDOWN],
  omitInSsr: true,
  text: 'menu_more_dropdown',
  name: 'more_dropdown',
  subs: [brandingTools, logoDesignBlogItem, hireDesignerItem, formAnLLC],
};

export const headerStructure: MultiLangHeaderStructure = {
  nav: [
    goToBrandStudioMobileMenuItem,
    goToMyBrandsMobileMenuItem,
    logoMakerNavigationItem,
    topLogoIndustries,
    topLogoDesigns,
    { ...brandingTools, screenWidths: [MOBILE, DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN] },
    { ...logoDesignBlogItem, screenWidths: [MOBILE, DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN] },
    SEPARATOR,
    { ...hireDesignerItem, screenWidths: [MOBILE, DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN] },
    { ...formAnLLC, screenWidths: [MOBILE, DESKTOP_COLLAPSE_INTO_MORE_DROPDOWN] },
    moreDropdown,
  ],
  mySites: {
    text: 'menu_my_sites',
    name: 'my_sites',
    href: 'https://www.wix.com/my-account',
  },
  userMenu: [
    goToBrandStudioDesktopAccountMenuItem,
    goToMyBrandsDesktopAccountMenuItem,
    {
      text: 'menu_account_settings',
      name: 'account_settings',
      href: 'https://www.wix.com/account/account-settings',
    },
    {
      text: 'menu_billing_and_payments',
      name: 'billing',
      href: 'https://www.wix.com/wix/api/PaymentConsole',
    },
    {
      text: 'menu_vouchers',
      name: 'vouchers',
      href: 'https://www.wix.com/account/vouchers',
    },
    {
      text: '',
      name: 'language',
    },
    {
      text: 'menu_sign_out',
      name: 'sign_out',
      href: 'https://www.wix.com/signout',
    },
  ],
};
