export const supportedLanguages = [
  'en',
  'de',
  'es',
  'fr',
  'it',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'ja',
  'ko',
  'tr',
  'da',
  'cs',
] as const;

export function isSupportedLanguage(language?: string): language is SupportedLanguage {
  return supportedLanguages.includes(language as SupportedLanguage);
}

export const languagesInLanguagePicker = [
  'en',
  'de',
  'es',
  'fr',
  'it',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'ja',
  'ko',
  'tr',
  'da',
  'cs',
];

export type SupportedLanguage = (typeof supportedLanguages)[number];

export const languageData: { [key: string]: string } = {
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ru: 'Русский',
  sv: 'Svenska',
  ja: '日本語',
  ko: '한국어',
  tr: 'Türkçe',
  da: 'Dansk',
  cs: 'Čeština',
  he: 'עברית',
};

export function getLanguageFullName(lang: string): string | undefined {
  return languageData[lang];
}

export function getAllLanguages() {
  return Object.keys(languageData);
}
