interface SkipButtonControllerProps {
  button: HTMLButtonElement;
  mainContentSelector?: string;
  isWixSite: boolean;
}

export class SkipButtonController {
  private readonly button: HTMLButtonElement;
  private mainContentSelector?: string;
  private readonly isWixSite: boolean;

  constructor({ button, mainContentSelector, isWixSite }: SkipButtonControllerProps) {
    this.button = button;
    this.mainContentSelector = mainContentSelector;
    this.isWixSite = isWixSite;
  }

  init() {
    if (this.mainContentSelector) {
      this.makeButtonFocusable();
    } else if (this.isWixSite) {
      document.addEventListener('keydown', this.tabClickHandler);
    }
    this.button.addEventListener('click', this.buttonClickHandler);
  }

  private buttonClickHandler = () => {
    if (this.mainContentSelector) {
      document.querySelector<HTMLButtonElement>(this.mainContentSelector)?.focus();
    }
  };

  private tabClickHandler = (event: KeyboardEvent) => {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9;
    if (isTabPressed) {
      this.mainContentSelector = this.getWixSiteMainContentSelector();

      if (this.mainContentSelector) {
        this.makeButtonFocusable();
        this.removeWixSiteSkipButton();
      }
    }
  };

  private getWixSiteMainContentSelector = () => {
    return window.wixEmbedsAPI?.getMainContentElementSelector?.();
  };

  private removeWixSiteSkipButton = () => {
    if (window.wixEmbedsAPI?.getSkipToMainContentButtonSelector) {
      const editorSkipButton = document.querySelector(window.wixEmbedsAPI.getSkipToMainContentButtonSelector());
      if (editorSkipButton) {
        editorSkipButton.remove();
      }
    }
  };

  private makeButtonFocusable() {
    this.button.tabIndex = 0;
  }
}
