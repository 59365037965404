import { createTranslator, Messages, Translator } from './translate';
import { Config } from './Config';
import { StructureBuilder } from '../data/StructureBuilder';
import { getAllLanguages, SupportedLanguage } from '../data/languages';
import { HeaderController } from '../components/Header/HeaderController';
import { DataFetcher } from './DataFetcher';
import { BiLogger } from './BiLogger';
import { Experiments } from '../experiments';
import { changeCurrentPageLanguage } from './routingServices';
import { MOBILE_MAX_WIDTH, MORE_DROPDOWN_MAX_WIDTH } from './breakpoints';

const BREAKPOINTS = [MOBILE_MAX_WIDTH, MORE_DROPDOWN_MAX_WIDTH];

interface HeaderFooterControllerParams {
  messages: Messages;
  config: Config;
  dataFetcher: DataFetcher;
  experiments: Experiments;
  biLogger: BiLogger;
}

export class HeaderFooterController {
  private readonly biLogger: BiLogger;
  private readonly t: Translator;
  private readonly config: Config;
  private readonly dataFetcher: DataFetcher;
  private readonly experiments: Experiments;

  private currentClosestBreakpoint: number;

  constructor({ messages, config, dataFetcher, experiments, biLogger }: HeaderFooterControllerParams) {
    this.t = createTranslator(messages);
    this.config = config;
    this.biLogger = biLogger;
    this.dataFetcher = dataFetcher;
    this.experiments = experiments;

    this.currentClosestBreakpoint = this.findClosestBreakpoint();
  }

  private readonly doesUserHaveBrandsOrLogos = async () => {
    const { brands } = await this.dataFetcher.fetchUserBrandsList();
    return brands?.length! > 0;
  };

  // closest bigger breakpoint
  private findClosestBreakpoint = () => [...BREAKPOINTS, +Infinity].find(value => value >= window.innerWidth)!;

  public async init() {
    this.renderHeader();

    window.addEventListener('resize', () => {
      const newClosestBreakpoint = this.findClosestBreakpoint();
      if (newClosestBreakpoint !== this.currentClosestBreakpoint) {
        this.renderHeader();
      }

      this.currentClosestBreakpoint = newClosestBreakpoint;
    });
  }

  private async renderHeader() {
    const { config, experiments, t, dataFetcher, biLogger } = this;
    const userProfileData = await this.dataFetcher.fetchUserProfileData().catch(() => null);
    const isUserLoggedIn = userProfileData ? true : false;
    const userHaveBrandsOrLogos = isUserLoggedIn ? await this.doesUserHaveBrandsOrLogos() : false;

    const builder = new StructureBuilder(config, { experiments });
    const headerStructure = builder.getHeaderStructure(this._extractRedirectToLinks(), {
      isUserLoggedIn,
      userHaveBrandsOrLogos,
    });

    const headerController = new HeaderController({
      headerStructure,
      t,
      config,
      dataFetcher,
      biLogger,
      experiments,
    });
    headerController.init({ userProfileData, userHaveBrandsOrLogos });
  }

  private _extractRedirectToLinks = () => {
    const location = window.location;
    return getAllLanguages().reduce<Record<string, string>>((acc, lang) => {
      const alternateLink = this._extractAlternateLink(lang);
      const alternateHref = alternateLink && alternateLink.getAttribute('href');
      const protocol = location && location.protocol ? location.protocol : 'https:';
      const domain = `${lang === 'en' ? 'www' : lang}.wix.com`;
      const path = location ? `/logo/maker${location.search || ''}` : '';
      acc[lang] = alternateHref || `${protocol}//${domain}${path}`;
      return acc;
    }, {} as Record<SupportedLanguage, string>);
  };

  private _extractAlternateLink(language: string) {
    if (typeof document === 'undefined') {
      return null;
    }
    return (
      document.querySelector(`link[rel="alternate"][hreflang="${language}"]`) ||
      document.querySelector(`link[rel="alternate"][hreflang="x-default"]`)
    );
  }
}
