(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("brandmaker-header-and-footer", [], factory);
	else if(typeof exports === 'object')
		exports["brandmaker-header-and-footer"] = factory();
	else
		root["brandmaker-header-and-footer"] = factory();
})((typeof self !== 'undefined' ? self : this), function() {
return 