import s from './UserMenu.scss';
import { UserProfileData } from '../../modules/DataFetcher';
import { Translator } from '../../modules/translate';
import { MenuItemWrapper } from '../MenuItemWrapper/MenuItemWrapper';
import { Config } from '../../modules/Config';

export interface UserMenuProps {
  config: Config;
  userProfileData: UserProfileData;
  userImage: string;
  t: Translator;
}

export const UserMenuMobile = ({ t, userImage, userProfileData: { userName } }: UserMenuProps) => {
  const signoutUrl = `https://wix.com/signout?redirectTo=${window.location.href}`;
  return `
    ${MenuItemWrapper({
      className: s.mobileContainer,
    })(`
    <div class="${s.buttonWrapper}">
     <img class="${s.image}" src="${userImage}" alt="${t('profile_image')}" />
      <div class="${s.mobileWrapper}">
        <span class="${s.userNameText}" title="${userName}">
          ${userName}
        </span>
        <a class="${s.signOut}" href="${signoutUrl}" data-bi-row="second" data-bi-name="sign_out">
              ${t('menu_sign_out')}
        </a>
      </div>
    </div>
    <div class="${s.separator}"></div>
`)}`;
};
