import biLogger from '@wix/web-bi-logger';
import { MOBILE_MAX_WIDTH } from './breakpoints';
import { SupportedLanguage } from '../data/languages';

export enum Action {
  hover = 'hover',
  click = 'click',
  openTab = 'open_tab',
  closeTab = 'close_tab',
  redirectToOnboarding = 'redirect_to_onboarding',
}

interface OpenTabParams {
  tab?: string;
}

interface CloseTabParams {
  tab?: string;
}

interface RedirectToOnboardingParams {}

interface ClickButtonParams {
  button_name?: string;
  to?: string;
  tab?: string;
}

interface HoverButtonParams {
  button_name?: string;
  to?: string;
  hover_ms: number;
  tab?: string;
}

interface LanguageChangeParams {
  destination_lng: string;
}

type LogActionParams = (
  | OpenTabParams
  | CloseTabParams
  | ClickButtonParams
  | HoverButtonParams
  | RedirectToOnboardingParams
) & {
  action: Action;
};

interface MainCTAParams {
  inputText: string;
}
interface BiLoggerParams {
  version?: string;
  language: SupportedLanguage;
}

export class BiLogger {
  private readonly version?: string;
  private readonly language: SupportedLanguage;

  constructor({ version, language }: BiLoggerParams) {
    this.version = version;
    this.language = language;
  }

  private readonly headerAndFooterBiLogger = biLogger.factory({ endpoint: 'hp' }).updateDefaults({ src: 19 }).logger();

  private readonly headerAndFooterOldBiLogger = biLogger
    .factory({ endpoint: 'hf' })
    .updateDefaults({ src: '19' })
    .logger();

  private readonly headerAndFooterNewBiLogger = biLogger
    .factory({ endpoint: 'any-marketing-lp' })
    .updateDefaults({ src: 19 })
    .logger();

  public loaded() {
    const load_time = new Date().getTime() - (window.WIX_HF_START_TIME ?? 0);
    this.headerAndFooterOldBiLogger.log({
      evid: '514',
      component: 'header v2',
      is_mobile: window.innerWidth <= MOBILE_MAX_WIDTH,
      load_time,
      origin: window.location.href,
      version: this.version,
    });
  }

  public startLoading() {
    this.headerAndFooterOldBiLogger.log({
      evid: '500',
      component: 'header v2',
      is_mobile: window.innerWidth <= MOBILE_MAX_WIDTH,
      origin: window.location.href,
    });
  }

  public clickButton(params: ClickButtonParams) {
    return this.logLinkAction({ action: Action.click, ...params });
  }

  public clickOnMainCTA(params: MainCTAParams) {
    this.headerAndFooterNewBiLogger.log({
      evid: '2006',
      buttonName: 'main cta',
      inputTextType: 'business name',
      pageUrl: window.location.href,
      ...params,
    });
  }

  public redirectToOnboarding(params: RedirectToOnboardingParams = {}) {
    return this.logLinkAction({ action: Action.redirectToOnboarding, ...params });
  }

  public openTab(params: OpenTabParams) {
    return this.logLinkAction({ action: Action.openTab, ...params });
  }

  public closeTab(params: CloseTabParams) {
    return this.logLinkAction({ action: Action.closeTab, ...params });
  }

  public hoverButton(params: HoverButtonParams) {
    return this.logLinkAction({ action: Action.hover, ...params });
  }

  public languageChange(params: LanguageChangeParams) {
    return this.headerAndFooterBiLogger.log({
      evid: '313',
      origin: window.location.href,
      version: this.version,
      origin_lng: this.language,
      ...params,
    });
  }

  private logLinkAction(params: LogActionParams) {
    return this.headerAndFooterBiLogger.log({
      evid: '349',
      from: window.location.href,
      ...params,
    });
  }
}

export function fireBiEventsOnHover({
  element,
  name,
  href,
  logger,
  tab,
}: {
  element: Element;
  name?: string;
  href?: string;
  logger: BiLogger;
  tab?: string;
}) {
  const { onMouseLeave, onMouseEnter } = ((buttonName?: string, link?: string) => {
    let startTime = 0;
    return {
      onMouseEnter: () => {
        startTime = new Date().getTime();
      },
      onMouseLeave: () => {
        const endTime = new Date().getTime();
        const params = { button_name: buttonName, hover_ms: endTime - startTime };
        if (link) {
          Object.assign(params, { to: link });
        }

        if (tab) {
          Object.assign(params, { tab });
        }
        logger.hoverButton(params);
      },
    };
  })(name, href);
  element.addEventListener('mouseenter', onMouseEnter);
  element.addEventListener('mouseleave', onMouseLeave);
}
